@import 'styles/variables';
@import 'styles/extensions';

.sorter {
  display: flex;
  flex-direction: column;
  padding-left: 20px;

  &__arrow-wrapper {
    height: 14px;

    &:hover {
      outline: 1px solid $white;
    }
  }

  &__up-arrow,
  &__down-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    margin: 4px 0 0 0;
    padding: 0;
    font-size: 10px;
    transform: rotate(-135deg);
    cursor: pointer;    

    &_hide {
      display: none;
    }
  }

  &__down-arrow {
    margin: -3px 0px 0px 1px;
    transform: rotate(45deg);
  }
}