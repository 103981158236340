@import 'styles/variables';
@import 'styles/extensions';

.game-form {
  overflow-y: hidden;

  &__logo-wrapper {    
    height: 100vh;
    overflow: hidden;
  }

  &__form-wrap {
    max-width: 280px;
    margin: 0 auto;
  }
  
  &__image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: $white;
    opacity: 0;
    animation: fadeOut 2.5s;
    animation-delay: 2s;
    animation-fill-mode: backwards;
  }

  &__logo-image {
    width: 100%;
    width: fit-content;
    height: 100vh;
  }

  &__form-title {
    max-width: 280px;
    margin-bottom: 10px;
    font-weight: normal;
    font-size: 32px;
    font-family: $secondary-font;
    line-height: 36px;
  }

  &__form-description {
    max-width: 280px;
    margin-bottom: 40px;

    &_bottom {
      max-width: 280px;
      font-size: 13px;
      line-height: 16px;
    }
  }

  &__form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding-top: 50px;
    opacity: 0;
    animation: fadeIn 3s;
    animation-fill-mode: forwards;

    .form-box {
      &__row {
        width: 100%;
      }
    }

    .form-input {
      min-width: 280px;
    }
  }

  &__button {
    @extend %button-colored;
    margin-top: 25px;

    &.disabled {
      opacity: 0.6;

      &:hover {
        color: $white;
        background: linear-gradient(90deg, #00a3cf 0%, #5552A0 100%);
      }
    }
  }
}


@keyframes fadeOut {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}