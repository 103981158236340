@import 'styles/variables';
@import 'styles/extensions';

.tabs {
  &__container {
    width: 100%;
    padding-top: 90px;
    overflow: hidden;

    @media (min-width: 2560px) {
      padding-top: calc(90px * #{$zoom});
    }
  }

  &__headline {
    display: flex;
    list-style: none;

    @media (max-width: 640px) {
      flex-direction: column;
    }
  }

  &__tab {
    position: relative;
    margin-right: 2%;
    margin-bottom: 30px;
    padding-bottom: 15px;
    font-size: 16px;
    font-family: $secondary-font;
    line-height: 24px;
    text-transform: uppercase;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    @media (min-width: 2560px) {
      margin-right: calc(2% * #{$zoom});
      margin-bottom: calc(30px * #{$zoom});
      padding-bottom: calc(15px * #{$zoom});
      font-size:calc(16px * #{$zoom});
      line-height: calc(24px * #{$zoom});
    }

    &::after {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0;
      height: 2px;
      background: $white;
      transition: width 0.3s;
      content: '';

      @media (min-width: 2560px) {
        height: calc(2px * #{$zoom});
      }
    }

    &.selected {
      &::after {
        left: 0;
        width: 100%;
      }
    }
  }

  &__content-item {
    position: absolute;
    top: 0;
    opacity: 0;
    transition: opacity 0.2s ease-in-out, position 0.25s linear;

    &.selected {
      position: relative;
      opacity: 1;
      transition: all 0.2s ease-in-out 0.2s;
    }
  }
}