@import 'styles/variables';
@import 'styles/extensions';

.event-ad {
  h3 {
    padding-bottom: 35px;
    color: $light-blue;
    font-weight: 800;
    font-size: 32px;
    font-family: $secondary-font;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: left;

    @media (min-width: 2560px) {
      padding-bottom: calc(35px * #{$zoom});
      font-size: calc(32px * #{$zoom});
      line-height: calc(36px * #{$zoom});
    }

    @media screen and (max-width: 450px) {
      font-size: 24px;
      line-height: 24px;
    }
  }

  p {
    max-width: 690px;
    font-size: 20;
    line-height: 32px;

    @media (min-width: 2560px) {
      max-width: calc(690px * #{$zoom});
      font-size: calc(20px * #{$zoom});
      line-height: calc(32px * #{$zoom});
    }
  }

  .game-form__button {
    width: 156px;
    height: 32px;
    margin-left: 0;
    line-height: 32px;
    cursor: pointer;

    @media (min-width: 2560px) {
      width: calc(156px * #{$zoom});
      height: calc(32px * #{$zoom});
      line-height: calc(32px * #{$zoom});
    }
  }
}