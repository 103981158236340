@import 'styles/variables';
@import 'styles/extensions';

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 96px;
  background: $background-second;

  &__wrapper {
    @extend %wrapper;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 475px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__logo {
    width: 100%;
    max-width: 112px;  
    
    @media (min-width: 2560px) {
      max-width: calc(112px * #{$zoom});
    }

    @media (max-width: 475px) {
      margin-left: -8px;
    }
  }

  &__copyright {
    color: $footer-gray;
    font-size: 13px;
    
    @media (min-width: 2560px) {
      font-size: calc(14px * #{$zoom});
      line-height: calc(18px * #{$zoom});
    }
  }
}