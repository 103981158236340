@import 'styles/variables';
@import 'styles/extensions';

.randomizer {
  &__container {
    width: 100%;
    margin-bottom: 60px;
  }

  &__button {
    margin-top: 25px!important;
    margin-bottom: 25px!important;
    font-weight: 800!important;
  }

  &__message-text,
  &__results-text {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  &__results-text {
    opacity: 0;
  }

  &__results {
    margin-bottom: 60px;
  }

  &__results-winner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px auto;
  }

  &__results-winner-number,
  &__results-winner-nickname,
  &__results-winner-time {
    display: inline-block;
    color: $winner-blue;
    font-size: 24px;
    font-family: $secondary-font;
    line-height: 24px;
    text-transform: uppercase;
  }
   
  &__results-winner-number,
  &__results-winner-nickname {
    margin-right: 20px;
  }
}

.fade {
  opacity: 1;
  transition: all 0.2s ease-in;
}