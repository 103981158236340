@import 'styles/variables';
@import 'styles/extensions';

.results-table {
  width: 100%;

  &__results {
    overflow: hidden;
  }

  &__list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 16px;
    background: linear-gradient(90deg, #00A3CF 0%, #5552A0 100%);

    @media (min-width: 2560px) {
      height: calc(50px * #{$zoom});
      padding: calc(16px * #{$zoom});
      font-size: calc(16px * #{$zoom});
    }

    .results-table__list-nickname {
      padding: 16px 0;

      @media (min-width: 2560px) {
        padding: calc(16px * #{$zoom}) 0;
      }
    }

    .results-table__list-time {     
      display: flex;
      align-items: center;
      height: 50px;
      padding-left: 20px;

      @media (min-width: 2560px) {
        height: calc(50px * #{$zoom});
      }

      &:hover {
        background: linear-gradient(90deg, #00A3CF 0%, #5552A0 100%);
      }
    }
  }

  &__search {
    padding-bottom: 32px;

    @media (min-width: 2560px) {
      padding-bottom: calc(32px * #{$zoom});
    }

    .form-input__text {
      max-width: 240px;
      height: 35px;
      font-weight: 700;

      @media (min-width: 2560px) {
        max-width: calc(240px * #{$zoom});
        height: calc(35px * #{$zoom});
      }

      &::-webkit-input-placeholder {
        color: $white;
        font-weight: 400;
        font-family: $basic-font;
        text-transform: none;
      }
  
      &::-moz-placeholder {
        color: $white;
        font-weight: 400;
        font-family: $basic-font;
        text-transform: none;
      }
  
      &:-moz-placeholder {
        color: $white;
        font-weight: 400;
        font-family: $basic-font;
        text-transform: none;
      }
  
      &:-ms-input-placeholder {
        color: $white;
        font-weight: 400;
        font-family: $basic-font;
        text-transform: none;
      }
    }

    .form-box__col {
      position: relative;
    }
  }

  &__form-button {
    position: absolute;
    top: 5px;
    left: 210px;
    width: 28px;
    height: 28px;
    color: $white;
    font-size: 11px;
    background: transparent;
    border: none;
    cursor: pointer;

    @media (min-width: 2560px) {
      top: calc(5px * #{$zoom});
      left: calc(210px * #{$zoom});
      width: calc(28px * #{$zoom});
      height: calc(28px * #{$zoom});
      font-size: calc(11px * #{$zoom});
    }
  }

  &__list-rank {
    flex-basis: 10%;
    font-weight: bold;

    @media (min-width: 2560px) {
      font-size: calc(16px * #{$zoom});
    }

    @media (max-width: 475px) {
      padding-right: 15px;
    }
  }

  &__list-nickname,
  &__list-time {
    flex-basis: 45%;

    @media (min-width: 2560px) {
      font-size: calc(16px * #{$zoom});
    }
  }

  &__list-nickname {
    word-break: break-all;
  }

  &__list-time {
    margin-left: 10px;
    padding-left: 20px;

    @media (max-width: 475px) {
      flex-basis: 37%;
    }
  }
  
  &__list {
    list-style: none;
  }

  &__list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 16px;

    @media (min-width: 2560px) {
      padding: calc(24px * #{$zoom}) calc(16px * #{$zoom});
    }

    &:nth-child(even) {
      background: #00A3CF33;
    }

    .results-table__list-rank {
      padding-left: 13px;

      @media (min-width: 2560px) {
        padding-left: calc(13px * #{$zoom});
      }

      @media (max-width: 475px) {
        padding-right: 26px;
      }
    }

    .results-table__list-nickname {
      word-break: break-all;

      @media (max-width: 475px) {
        flex-basis: 55%;
        padding-left: 5px;
      }
    }

    .results-table__list-time {
      margin-left: 10px;

      @media (max-width: 475px) {
        flex-basis: 40%;
      }
    }
  }

  .results-table__list-item-enter {
    transform: translateY(400px);
  }
  
  .results-table__list-item-enter-done {
    transform: translateY(0);
    transition: transform 1s ease, box-shadow 0.2s ease;
  }
}

.empty-placeholder {
  h3 {
    padding-bottom: 35px;
    color: $light-blue;
    font-weight: 800;
    font-size: 32px;
    font-family: $secondary-font;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: left;

    @media (min-width: 2560px) {
      padding-bottom: calc(35px * #{$zoom});
      font-size: calc(32px * #{$zoom});
      line-height: calc(36px * #{$zoom});
    }

    @media screen and (max-width: 450px) {
      font-size: 24px;
      line-height: 24px;
    }
  }

  p {
    max-width: 690px;
    font-size: 20;
    line-height: 32px;

    @media (min-width: 2560px) {
      max-width: calc(690px * #{$zoom});
      font-size: calc(20px * #{$zoom});
      line-height: calc(32px * #{$zoom});
    }
  }
}
