@font-face {
  font-weight: 400;
  font-family: 'ProximaNova';
  src: url('https://info.softserveinc.com/hubfs/fonts/ProximaNovaBlack.otf');
}

@font-face {
  font-weight: 900;
  font-family: 'ProximaNova';
  src: url('https://info.softserveinc.com/hubfs/ibm-think/assets/fonts/ProximaNova/Regular/ProximaNova-Bold.ttf');
}

@font-face{
  font-weight: normal;
  font-family: 'OpenSans';
  src: url('https://info.softserveinc.com/hubfs/fonts/OpenSans-Regular.ttf');
}